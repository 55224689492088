import React, { useEffect, useState } from "react";
import "./App.css";
import HTMLFlipBook from "react-pageflip";

function App() {
  const images = [
    "Page 01.webp",
    "Page 02.webp",
    "Page 03.webp",
    "Page 04.webp",
    "Page 05.webp",
    "Page 06.webp",
  ];

  const [dimensions, setDimensions] = useState(getDimensions());

  useEffect(() => {
    function handleResize() {
      setDimensions(getDimensions());
    }

    // Set up event listeners for resize and orientation changes
    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  function getDimensions() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const isPortrait = height > width; // Determine if the device is in portrait mode
    // console.log("isPortrait", isPortrait, "width", width);
    if (width <= 768) {
      // Mobile
      if (isPortrait) {
        return { width: width * 0.9, height: height * 0.7 }; // Portrait mode on mobile
      } else {
        return { width: width * 0.35, height: height * 0.88 }; // Landscape mode on mobile
      }
    } else if (width <= 1024) {
      // Tablet
      if (isPortrait) {
        return { width: width * 0.85, height: height * 0.75 };
      } else {
        return { width: width * 0.35, height: height * 0.9 };
      }
    } else if (width <= 1200) {
      // Desktop
      return { width: width * 0.45, height: height * 0.95 };
    } else if (width <= 1440) {
      // Large Desktop
      return { width: width * 0.4, height: height * 0.95 };
    } else {
      // 4K
      return { width: width * 0.4, height: height * 0.95 };
    }
  }

  return (
    <div className="App">
      <HTMLFlipBook
        width={dimensions.width}
        height={dimensions.height}
        showCover={true}
      >
        {images.map((image, index) => (
          <img
            key={index}
            src={`${process.env.PUBLIC_URL}/assets/${image}`}
            alt={`page ${index + 1}`}
            style={{
              width: "50%",
              // height: "100%",
              objectFit: "contain",
            }}
          />
        ))}
      </HTMLFlipBook>
    </div>
  );
}

export default App;
